import {EditorView, WidgetType} from '@uiw/react-codemirror'

export class CheckboxWidget extends WidgetType {
  constructor(readonly checked: boolean) {
    super()
  }

  eq(other: CheckboxWidget) {
    return other.checked === this.checked
  }

  toDOM() {
    const wrap = document.createElement('span')
    wrap.setAttribute('aria-hidden', 'true')
    wrap.className = 'cm-boolean-toggle'
    const box = wrap.appendChild(document.createElement('input'))
    box.type = 'checkbox'
    box.className = 'checkbox'
    box.checked = this.checked
    return wrap
  }

  ignoreEvent(): boolean {
    return false
  }
}

export function toggleCheckbox(view: EditorView, pos: number) {
  const before = view.state.doc.sliceString(Math.max(0, pos - 3), pos)
  console.log(pos, before)
  if (before === '[ ]') {
    view.dispatch({
      changes: {
        from: pos - 3,
        to: pos,
        insert: '[x]',
      },
    })
  } else if (before === '[x]') {
    view.dispatch({
      changes: {
        from: pos - 3,
        to: pos,
        insert: '[ ]',
      },
    })
  } else {
    return false
  }

  return true
}
