import React, {useState} from 'react'

import usePartySocket from 'partysocket/react'

import {SINGLETON_ROOM_ID, type Rooms} from '../../party/rooms'

import styles from './Lobby.module.css'
import {EXEC_TODAY_STR} from '../util/settings'
import {IconPlus, IconUsers} from '@tabler/icons-react'

export default function Lobby({
  currentRoom,
  setCurrentRoom,
}: {
  currentRoom: string
  setCurrentRoom: (room: string) => void
}) {
  const [rooms, setRooms] = useState<Rooms>({})

  usePartySocket({
    party: 'rooms',
    room: SINGLETON_ROOM_ID,
    onMessage(evt) {
      const data = JSON.parse(evt.data)
      if (data.type === 'rooms') {
        setRooms(data.rooms as Rooms)
      }
    },
  })

  return (
    <div className={styles.sidebar}>
      <h3>All Documents</h3>
      <ul>
        {Object.entries(rooms)
          .sort(([r1], [r2]) => (r1 < r2 ? -1 : r1 > r2 ? 1 : 0))
          .map(([room, count]) => {
            const isCurrent = room === currentRoom
            return (
              <button
                className={styles.button}
                onClick={() => setCurrentRoom(room)}
                disabled={isCurrent}
                key={room}
              >
                {room}
                {count ? (
                  <div className={styles.userCount}>
                    <IconUsers height="1rem" />
                    <span className={styles.count}>{count}</span>
                  </div>
                ) : (
                  <></>
                )}
              </button>
            )
          })}
      </ul>
      <div className={styles.docControls}>
        {currentRoom !== EXEC_TODAY_STR ? (
          <button
            className={styles.button}
            onClick={() => {
              setCurrentRoom(EXEC_TODAY_STR)
            }}
          >
            Go to Today
          </button>
        ) : (
          <div />
        )}
        <button
          className={styles.button}
          onClick={() => {
            const result = prompt('Enter the name of your new document:')
            if (result) {
              setCurrentRoom(result)
            }
          }}
        >
          <IconPlus height="1rem" />
        </button>
      </div>
    </div>
  )
}
