import {
  Decoration,
  WidgetType,
  EditorView,
  type DecorationSet,
  EditorState,
  Range,
} from '@uiw/react-codemirror'
import {RangeSet, StateField} from '@uiw/react-codemirror'
import {syntaxTree} from '@codemirror/language'

import markdoc, {type Config} from '@markdoc/markdoc'
import {CheckboxWidget} from './checkboxWidget'

const patternTag =
  /{%\s*(?<closing>\/)?(?<tag>[a-zA-Z0-9-_]+)(?<attrs>\s+[^]+)?\s*(?<self>\/)?%}\s*$/m

class RenderBlockWidget extends WidgetType {
  rendered: string

  constructor(
    public source: string,
    config: Config
  ) {
    super()

    const document = markdoc.parse(source)
    const transformed = markdoc.transform(document, config)
    this.rendered = markdoc.renderers.html(transformed)
  }

  eq(widget: RenderBlockWidget) {
    return widget.source === this.source
  }

  toDOM(): HTMLElement {
    const content = document.createElement('div')
    content.setAttribute('contenteditable', 'false')
    content.className = 'cm-markdoc-renderBlock'
    content.innerHTML = this.rendered
    return content
  }

  ignoreEvent(): boolean {
    return false
  }
}

function replaceBlocks(state: EditorState, config: Config, from?: number, to?: number) {
  const decorations: Range<Decoration>[] = []
  const [cursor] = state.selection.ranges

  const tags: [number, number][] = []
  const stack: number[] = []

  syntaxTree(state).iterate({
    from,
    to,
    enter(node) {
      if (!['Table', 'Blockquote', 'MarkdocTag', 'Task'].includes(node.name)) {
        return
      }

      if (node.name === 'MarkdocTag') {
        const text = state.doc.sliceString(node.from, node.to)
        const match = text.match(patternTag)

        if (match?.groups?.self) {
          tags.push([node.from, node.to])
          return
        }

        if (match?.groups?.closing) {
          const last = stack.pop()
          if (last) {
            tags.push([last, node.to])
          }
          return
        }

        stack.push(node.from)
        return
      } else if (node.name === 'Task') {
        if (cursor.from >= node.from && cursor.to <= node.to) {
          return false
        }

        const isChecked = state.doc.sliceString(node.from, node.to).trim().startsWith('[x]')
        const decoration = Decoration.replace({
          widget: new CheckboxWidget(isChecked),
          side: 1,
        })
        decorations.push(decoration.range(node.from, node.from + 3))
        return
      }
      const text = state.doc.sliceString(node.from, node.to)
      const decoration = Decoration.replace({
        widget: new RenderBlockWidget(text, config),
        block: true,
      })

      decorations.push(decoration.range(node.from, node.to))
    },
  })
  for (const [from, to] of tags) {
    if (cursor.from >= from && cursor.to <= to) {
      continue
    }
    const text = state.doc.sliceString(from, to)
    const decoration = Decoration.replace({
      widget: new RenderBlockWidget(text, config),
      block: true,
    })

    decorations.push(decoration.range(from, to))
  }

  return decorations
}

export default function (config: Config) {
  return StateField.define<DecorationSet>({
    create(state) {
      return RangeSet.of(replaceBlocks(state, config))
    },
    update(decorations, transaction) {
      return RangeSet.of(replaceBlocks(transaction.state, config), true)
    },
    provide(field) {
      return EditorView.decorations.from(field)
    },
  })
}
