import React, {useRef, useMemo} from 'react'

import {useCodeMirror} from '@uiw/react-codemirror'
import useYProvider from 'y-partykit/react'
import {yCollab} from 'y-codemirror.next'
import {languages} from '@codemirror/language-data'
import {Strikethrough, Table, TaskList} from '@lezer/markdown'

import 'react-quill/dist/quill.snow.css'

import richMarkdoc from './rich-markdoc/plugin'
import markdocConfig from './rich-markdoc/markdocConfig'
import styles from './Editor.module.css'

export default function Editor({room}: {room: string}) {
  const editorRef = useRef<HTMLDivElement | null>(null)

  const provider = useYProvider({
    room,
  })

  const ytext = useMemo(() => provider.doc.getText('text'), [provider])

  useCodeMirror({
    container: editorRef.current,
    extensions: [
      yCollab(ytext, provider.awareness),
      richMarkdoc({
        markdoc: markdocConfig,
        lezer: {
          codeLanguages: languages,
          extensions: [Strikethrough, Table, TaskList],
        },
      }),
    ],
  })

  return (
    <div className={styles.editor}>
      <h1>{room}</h1>
      <div ref={editorRef} />
    </div>
  )
}
