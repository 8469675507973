import './styles.css'

import React, {useState} from 'react'
import {createRoot} from 'react-dom/client'

import Lobby from './components/Lobby'
import Editor from './components/Editor/Editor'
import {EXEC_TODAY_STR} from './util/settings'

function App() {
  const [currentRoom, setCurrentRoom] = useState(EXEC_TODAY_STR)

  return (
    <main>
      <Editor room={currentRoom} key={currentRoom} />
      <Lobby currentRoom={currentRoom} setCurrentRoom={setCurrentRoom} />
    </main>
  )
}

createRoot(document.getElementById('app')!).render(<App />)
